import * as React from "react";
import "../styles/index.scss";
import whitepaper from "../resources/bitcoin.pdf";
import "../styles/index.scss";

export default function WhitepaperPage() {
  return (
    <main>
      <embed 
        src={whitepaper} 
        type="application/pdf"
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"
      />
    </main>
  )
}
